
import { Component, Vue } from "vue-property-decorator"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import ConsultantContractSummary from "@/components/consultant/ConsultantContractSummary.vue"
import EventBus from "@/components/EventBus"
import {
  ConsultantContractFragmentFragment,
  ConsultantContractsQuery,
  RequestConsultantContractApprovalMutation,
  RequestConsultantContractApprovalMutationMutation,
  RequestConsultantContractApprovalMutationMutationVariables,
} from "@/gql"
import PeraltaUserAgreement from "@/components/templates/PeraltaUserAgreement.vue"
import ConflictOfInterestPolicy from "@/components/templates/ConflictOfInterestPolicy.vue"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"

@Component({
  components: {
    AppDetailPage,
    PeraltaUserAgreement,
    ConflictOfInterestPolicy,
    ConsultantContractSummary,
    FeedBackMessage,
    ActivityTimeline,
  },
  apollo: {
    contract: {
      query: ConsultantContractsQuery,
      loadingKey: "loading",
      variables() {
        return {
          filter: {
            id: this.$route.params.id,
          },
        }
      },
      update(data) {
        return data?.consultantContracts.data[0]
      },
    },
  },
})
export default class ContractDetail extends Vue {
  contract: ConsultantContractFragmentFragment | null = null
  loading = 0
  isLoading = false
  showSubmitForApprovalDialog = false

  async submitContractForApproval() {
    this.isLoading = true

    const id = this.contract!.id

    const result = await this.mutate<
      RequestConsultantContractApprovalMutationMutation,
      RequestConsultantContractApprovalMutationMutationVariables
    >({
      mutation: RequestConsultantContractApprovalMutation,
      variables: {
        id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.isLoading = false
      },
    })

    if (result.data && !result.data.requestConsultantContractApproval.error) {
      this.addSuccess("Contract sent for approval successfully")
      this.showSubmitForApprovalDialog = false
    }
  }

  get queryVars() {
    return {
      filter: {
        id: this.$route.params.id,
      },
    }
  }

  get refetchQueries() {
    return [
      {
        query: ConsultantContractsQuery,
        variables: { ...this.queryVars },
      },
    ]
  }

  onApprovalComplete() {
    EventBus.$emit("update-contract-detail-query")
  }
}
